<template>
  <div>
    <Filters @apply="getAllNotificationsList" />
    <NotificationReminderContainer
      :update-pagination="updatePagination"
      :is-loading-component="isLoadingComponent"
      :notification-actions="notificationActions"
      @update="typeAction"
      @filterRecord="filterRecord"
    />
    <!-- SCHEDULED NOTIFICATION -->
    <ScheduledNotificationCreateUpdate
      v-if="scheduleModel"
      :show-model="scheduleModel"
      :label="currentActionObject.label"
      :options="currentActionObject.optionsList"
      :concerned="currentActionObject.concernedList"
      :feature-and-actions="currentActionObject"
      :edit="currentNotificationObject"
      @handleClick="toogleScheduleModel"
      @update="filterRecord"
    />
    <!-- UNSCHEDULED NOTIFICATION -->
    <UnScheduledNotificationCreateUpdate
      v-if="unScheduleModel"
      :show-model="unScheduleModel"
      :label="currentActionObject.label"
      :options="currentActionObject.optionsList"
      :feature-and-actions="currentActionObject"
      :edit="currentNotificationObject"
      module="period_attendance"
      @handleClick="toogleUnScheduleModel"
      @update="updateNotificationsList"
      @addNotificationAlert="addNotification"
    />
  </div>
</template>

<script>
import ScheduledNotificationCreateUpdate from '@/src/components/Settings/notifications/AddEditNotificationAlertTemplate1.vue'
import UnScheduledNotificationCreateUpdate from '@/src/components/Settings/notifications/CreateUnScheduleNotification.vue'
import NotificationReminderContainer from '@/src/components/Settings/notifications/NotificationReminderContainer.vue'
import Filters from '@views/settings/notifications/attendance/period/PeriodAttendanceNotificationFilters.vue'
/* Utils or mixins */
import { mapActions } from 'vuex'
import notificationMixin from '@src/mixins/notification-mixin.js'
import NOTIFICATION_PATHS from '@src/constants/notification-constants.js'
import { getNotificationsOptionsList } from '@utils/notifications'
export default {
  components: {
    ScheduledNotificationCreateUpdate,
    UnScheduledNotificationCreateUpdate,
    NotificationReminderContainer,
    Filters,
  },
  page: {
    title: 'Notification | Period Attendance',
    meta: [
      {
        name: 'description',
        content: 'Period Attendance Notification System',
      },
    ],
  },
  mixins: [notificationMixin],
  data() {
    return {
      dataPayload: {
        payload: '',
        path: NOTIFICATION_PATHS.PERIOD_ATTENDANCE,
      },
    }
  },

  methods: {
    setActionList() {
      this.notificationActions = {
        mark_attendacne: {
          title: 'Reminder for Mark Attendance',
          label: 'mark attendance',
          feature: 'period_attendance',
          action: 'mark_attendacne',
          loading: false,
          count: 0,
        },
      }
    },
    setConcernedAndOptionsList() {
      this.notificationActions.mark_attendacne.optionsList = getNotificationsOptionsList([
        'concerned_student',
        'concerned_student_main_guardian',
        'concerned_student_guardians',
      ])
    },

    setRightBar() {
      const rightBarContent = {
        header: {
          title: 'NOTIFICATION_REMINDER',
          buttons: [
            {
              title: 'CREATE_NEW_NOTIFICATION_REMINDER',
              classes: ['primary-button-right'],
              options: [
                {
                  /* FEE CREATION */
                  title: 'RB_MARK_ATTENDANCE',
                  action: {
                    func: () => {
                      this.currentActionObject = this.notificationActions.mark_attendacne
                      this.toogleUnScheduleModel()
                    },
                  },
                  payload: {},
                },
              ],
            },
          ],
        },
      }
      this.setRightbarContent(rightBarContent)
    },

    ...mapActions('layout', ['setRightbarContent']),
  },
}
</script>

<style lang="scss" module>
.minHieght80 {
  min-height: 80vh;
}
</style>
